import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { storeGoodsList, getSysMmenuList, getSysMBottommenuList, userInfo } from '../assets/api'
import WebStorageCache from 'web-storage-cache'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    header: {
      SubMenu: [],
      SubSubMenu: [],
      SubSubMenuReady: 0,
      SubBottomMenu: [],
    },
    siteScreenWidth: 1920,
    siteScreenHeight: 1080,
    isUser: VueCookies.get('myCookie') ? true : false,
    loginremember: { login: '', pw: '' },
    isGetUser: false,
    userData: {},
  },
  plugins: [createPersistedState()], //加入
  mutations: {
    setSiteScreenWidth(state, data) {
      state.siteScreenWidth = data
    },
    setSiteScreenHeight(state, data) {
      state.siteScreenHeight = data
    },
    async setSysMmenuListFun(state, data) {
      state.header.SubMenu = data
    },
    async setSysMmenuSubItemFun(state, data) {
      state.header.SubSubMenu[data.idx] = data.data
      state.header.SubSubMenuReady = state.header.SubSubMenuReady += 1
    },
    setSysMmenuSubListFun(state, data) {
      state.header.SubSubMenu = data
      state.header.SubSubMenuReady = 0
    },
    async setSysMBottommenuListFun(state, data) {
      state.header.SubBottomMenu = data
    },

    setUserInfoFun(state, data) {
      state.userData = data
    },

    async updateBalanceFun(state, data) {
      if (state.isUser) {
        try {
          var cachecheck = new WebStorageCache({
            // [option] 'localStorage', 'sessionStorage', window.localStorage, window.sessionStorage or
            // other storage instance implement [Storage API].
            // default 'localStorage'.
            storage: 'localStorage',
            // [option] //An expiration time, in seconds. default never .
            exp: Infinity,
          })
          var l_check = null
          if (cachecheck.get('myCookie2')) {
            l_check = cachecheck.get('myCookie2')
          }
          // var l_check = VueCookies.get("myCookie2");

          var l_isvaliddata = false

          if (l_check) {
            l_check.Balance = data
            state.userData = l_check
            if (state.userData && state.userData.Balance) {
              l_isvaliddata = true
              cachecheck.set('myCookie2', l_check)
            }
          }

          if (!l_isvaliddata) {
            let res = await userInfo()
            if (res && res.code === 100) {
              state.userData = res.data
              state.isGetUser = true
              // VueCookies.set("myCookie2", JSON.stringify(res.data));
              cachecheck.set('myCookie2', res.data)
            } else {
              //throw new Error(res.message);
            }
          }
        } catch (err) {
          console.log(err)
          //
        }
      }
    },
    loginremember(state, data) {
      state.loginremember = data
    },
    setIsUserFun(state, data) {
      state.isUser = data
      if (!data) {
        //VueCookies.remove("myCookie2");
        var cachecheck = new WebStorageCache({
          // [option] 'localStorage', 'sessionStorage', window.localStorage, window.sessionStorage or
          // other storage instance implement [Storage API].
          // default 'localStorage'.
          storage: 'localStorage',
          // [option] //An expiration time, in seconds. default never .
          exp: Infinity,
        })
        cachecheck.set('myCookie2', null)
      }
    },
    setIsGetUserFun(state, data) {
      state.isGetUser = data
    },
  },
  actions: {
    // 請求導航欄數據
    async sysMmenuListFun({ commit }) {
      try {
        return await getSysMmenuList().then((res) => {
          if (res && res.code === 100) {
            commit('setSysMmenuListFun', res.data)
            let subMenu = []
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                subMenu.push([])
              }
            }
            commit('setSysMmenuSubListFun', subMenu)
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                let _i = i
                try {
                  storeGoodsList({
                    cate_id: res.data[_i].CompanyID,
                  }).then((res2) => {
                    if (res2 && res2.code === 100) {
                      commit('setSysMmenuSubItemFun', {
                        idx: _i,
                        data: res2.data?.StoreGoods[0].Goods,
                      })
                    }
                  })
                } catch {}
              }
            }
          } else {
            //throw new Error(res.message);
          }
        })
      } catch (err) {
        //
      }
    },

    async sysMBottommenuListFun({ commit }) {
      try {
        return await getSysMBottommenuList().then((res) => {
          if (res && res.code === 100) {
            commit('setSysMBottommenuListFun', res.data)
          } else {
            // throw new Error(res.message);
          }
        })
      } catch (err) {
        //
      }
    },

    async userInfoFun({ state, commit }) {
      if (state.isUser) {
        try {
          var cachecheck = new WebStorageCache({
            // [option] 'localStorage', 'sessionStorage', window.localStorage, window.sessionStorage or
            // other storage instance implement [Storage API].
            // default 'localStorage'.
            storage: 'localStorage',
            // [option] //An expiration time, in seconds. default never .
            exp: Infinity,
          })
          var l_check = null
          if (cachecheck.get('myCookie2')) {
            l_check = cachecheck.get('myCookie2')
          }
          // var l_check = VueCookies.get("myCookie2");

          if (l_check) {
            commit('setUserInfoFun', l_check)
          } else {
            let res = await userInfo()
            if (res && res.code === 100) {
              commit('setUserInfoFun', res.data)
              state.isGetUser = true
              // VueCookies.set("myCookie2", JSON.stringify(res.data));
              cachecheck.set('myCookie2', res.data)
            } else {
              // throw new Error(res.message);
            }
          }
        } catch (err) {
          console.log(err)
          //
        }
      }
    },
  },
  getters: {
    SysMmenuListFun: (state) => state.header,
    isUser: (state) => state.isUser,
    loginremember: (state) => state.loginremember,
    isGetUser: (state) => state.isGetUser,
    userData: (state) => state.userData,
    siteScreenWidth: (state) => state.siteScreenWidth,
    siteScreenHeight: (state) => state.siteScreenHeight,
  },
})

export default store
