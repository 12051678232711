import axios from 'axios'
import router from '../router'
import VueCookies from 'vue-cookies'
import { store } from '../store/plugin'

//let _baseURL = "https://api.newgame168.com/";
//let _baseURL = "https://api.inf288.com/";
//let _baseURL = "https://api.nicegame188.com/";
//let _baseURL = "https://api.newgame168.com/";
//let _baseURL = "https://fuint-apiv2.dev2022.travelconnect.cn/";
//let _baseURL = "http://localhost:54921/";
//let _baseURL = "https://api.nicegame168.com/";
let _baseURL = 'https://api.easywin188.com/'
//let _baseURL = 'https://api.gamers666.com/'

// 创建 axios 实例
let _axiosInstance = axios.create({
  timeout: 500000, // 请求超时时间
  baseURL: _baseURL,
})

// 请求拦截器
let _axiosRequestInterceptor = {
  async resolve(_config) {
    console.log(_config)
    var l_temptoken = ''
    try {
      if (_config.method === 'get') {
        l_temptoken = _config.params.temptoken
      } else if (_config.method === 'post') {
        l_temptoken = _config.data.temptoken
      }
    } catch (err) {}

    if (l_temptoken && l_temptoken != null && l_temptoken != '') {
      //  console.log('temptoken:')
      // console.log(l_temptoken)
      _config.headers['Authorization'] = 'Bearer ' + l_temptoken
    } else if (VueCookies.get('myCookie')) _config.headers['Authorization'] = 'Bearer ' + VueCookies.get('myCookie')

    return _config
  },
  reject(_error) {
    return Promise.reject(_error)
  },
}

// 响应拦截器
let _axiosResponseInterceptor = {
  resolve(_response) {
    if (_response.data.code !== 100 && _response.data.code !== 2000) {
      alert(_response.data.message)
    } else if (_response.data.code === 2000) {
      VueCookies.remove('myCookie')
      //store.commit('setUserInfoFun', {})
      store.commit('setIsUserFun', false)

      alert(_response.data.message, '未经授权 - 账户从其他位置登录')
    } else {
      return _response.data
    }
  },
  reject(_error) {
    let _errorMessage = '请求錯誤'
    switch (_error.request.status) {
      case 0:
        _errorMessage = '请求被阻止或網絡發生問題'
        break
      case 401:
        _errorMessage = _error.response.data.msg || '登錄異常'
        router.push({ path: '/login' })
        break
      case 403:
        _errorMessage = _error.response.data.msg || '很抱歉，您的访问权限等级不够，联系管理员!'
        break
      case 500:
        _errorMessage = '服务器端发生了内部错误'
        break
    }
    alert(_errorMessage)
    // if (_error.response.status != 200) {}
    return Promise.reject(_error)
  },
}

_axiosInstance.interceptors.request.use(_axiosRequestInterceptor.resolve, _axiosRequestInterceptor.reject)
_axiosInstance.interceptors.response.use(_axiosResponseInterceptor.resolve, _axiosResponseInterceptor.reject)

export default _axiosInstance

export const baseURL = _baseURL
