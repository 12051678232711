export const MEMBER_RECORD_TYPE_NAMES = ['記錄', '充值記錄', '提現記錄', '投注記錄', '紅利記錄']
export const MEMBER_PAGE_FOR_BACKEND = [
  //'GameBalance',
  //'MemberSetting',
  'Withdraw',
  //'Deposit',
  //'MemberVip',
  //'MemberBank',
  'MemberActivity',
  'MemberRecord',
  //'Member',
]
