<template>
    <v-snackbar :value="showAlert" :timeout="alertTimeout" :color="alertType" top>
        {{ alertMessage }}
        <!-- <v-btn depressed color="white" @click="hide">關閉</v-btn> -->
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            showAlert: false,
            alertMessage: "",
            alertTimeout: 3000,
            alertType: ''
        };
    },
    methods: {
        show(message, type) {
            this.alertType = type
            this.alertMessage = message
            this.showAlert = true
            setTimeout(() => {
                this.hide()
            }, this.alertTimeout)
        },

        hide() {
            this.showAlert = false
        },
    },
};
</script>