import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/plugin'
import { MEMBER_RECORD_TYPE_NAMES, MEMBER_PAGE_FOR_BACKEND } from '@/plugins/memberRecordType'
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/views/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'MainIndex',
        component: () => import('@/views/pages/index.vue'),
      },
      {
        path: '/goods',
        name: 'Goods',
        component: () => import('@/views/pages/goods.vue'),
        meta: {},
      },
      {
        path: '/goods2',
        name: 'Goods2',
        component: () => import('@/views/pages/goods2.vue'),
        meta: {},
      },
      {
        path: '/games',
        name: 'Games',
        component: () => import('@/views/pages/games.vue'),
        meta: {},
      },

      {
        path: '/Article',
        name: 'Article',
        component: () => import('@/views/pages/article.vue'),
        meta: {},
      },
      {
        path: '/memberrefer',
        name: 'MemberRefer',
        component: () => import('@/views/pages/memberrefer.vue'),
        meta: {
          headerCaption: '立即推薦好友',
          headerTemplate: 'member',
          auth: true,
        },
      },

      {
        path: '/blog/link',
        name: 'Blog',
        component: () => import('@/views/pages/bloglink.vue'),
        meta: {},
      },
      {
        path: '/activitydetail',
        name: 'ActivityDetail',
        component: () => import('@/views/pages/activitydetail.vue'),
        meta: {
          headerCaption: '優惠申請',
        },
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/login.vue'),
        meta: {
          hiddenDesktopHeader: true,
          headerCaption: '登入',
          headerTemplate: 'login',
          auth: false,
        },
      },
      {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/views/pages/signup.vue'),
        meta: {
          hiddenDesktopHeader: true,
          headerCaption: '註冊',
          headerTemplate: 'login',
          auth: false,
        },
      },
      // {
      //   path: '/appdownload',
      //   name: 'AppDownload',
      //   component: () => import('@/views/pages/appdownload.vue'),
      //   meta: {
      //     headerCaption: '客戶端下載',
      //     headerTemplate: 'appdownload',
      //   },
      // },
      // {
      //   path: '/sponsor',
      //   name: 'Sponsor',
      //   component: () => import('@/views/pages/sponsor.vue'),
      //   meta: {
      //     // hiddenDesktopHeader: true,
      //     headerCaption: '贊助',
      //     headerTemplate: 'login',
      //   },
      // },
      {
        path: '/member',
        name: 'Member',
        component: () => import('@/views/pages/member.vue'),
        meta: {
          headerCaption: '我的',
          headerTemplate: 'member-home',
          auth: true,
        },
      },
      {
        path: '/memberrecord',
        name: 'MemberRecord',
        component: () => import('@/views/pages/memberrecord.vue'),
        meta: {
          headerCaption: MEMBER_RECORD_TYPE_NAMES,
          headerCaptionType: 'type',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/memberactivity',
        name: 'MemberActivity',
        component: () => import('@/views/pages/memberactivity.vue'),
        meta: {
          headerCaption: '活動優惠',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/memberbank',
        name: 'MemberBank',
        component: () => import('@/views/pages/memberbank.vue'),
        meta: {
          headerCaption: '銀行卡管理',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/rebate',
        name: 'Rebate',
        component: () => import('@/views/pages/rebate.vue'),
        meta: {
          headerCaption: '一鍵返水',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('@/views/pages/activity.vue'),
        meta: {},
      },
      {
        path: '/membervip',
        name: 'MemberVip',
        component: () => import('@/views/pages/membervip.vue'),
        meta: {
          headerCaption: 'VIP特權',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/pages/deposit.vue'),
        meta: {
          headerCaption: '存款',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/views/pages/withdraw.vue'),
        meta: {
          headerCaption: '取款',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/membersetting',
        name: 'MemberSetting',
        component: () => import('@/views/pages/membersetting.vue'),
        meta: {
          headerCaption: '個人資料',
          headerTemplate: 'member',
          auth: true,
        },
      },
      {
        path: '/gamebalance',
        name: 'GameBalance',
        component: () => import('@/views/pages/gamebalance.vue'),
        meta: {
          headerCaption: '遊戲餘額',
          headerTemplate: 'member',
          auth: true,
        },
      },
    ],
  },

  //
  {
    path: '',
    component: () => import('@/views/layouts/MainLayoutBlank.vue'),
    children: [
      {
        path: '/gameredirect',
        name: 'GameRedirect ',
        component: () => import('@/views/pages/gameredirect.vue'),
        meta: {
          hiddenGameAppBar: true,
        },
      },
    ],
  },

  {
    path: '',
    component: () => import('@/views/layouts/MainLayoutThree.vue'),
    children: [
      {
        path: '/transfermember',
        name: 'TransferMember',
        component: () => import('@/views/pages/transfermember.vue'),
        meta: {},
      },

      {
        path: '/memberpromo',
        name: 'MemberPromo',
        component: () => import('@/views/pages/memberpromo.vue'),
        meta: {},
      },
    ],
  },

  {
    path: '',
    component: () => import('@/views/layouts/MainLayoutTwo.vue'),
    children: [
      // {
      //   path: "/member",
      //   name: "Member",
      //   component: () => import("@/views/pages/member.vue"),
      //   meta: {},
      // },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  //console.log(from)
  //console.log(to)
  let isUser = store.getters?.isUser
  let auth = to.meta?.auth
  if (window) window.scrollTo(0, 0)
  if (
    (!to.query.ref && from.query.ref && from.query.ref != '') ||
    (!to.query.referfrom && from.query.referfrom && from.query.referfrom != '')
  ) {
    if (!to.query.ref && from.query.ref && from.query.ref != '') {
      to.query.ref = from.query.ref
    }
    if (!to.query.referfrom && from.query.referfrom && from.query.referfrom != '') {
      to.query.referfrom = from.query.referfrom
    }
    if (!to.query.token && from.query.token != undefined && from.query.token != '') {
      if (to.name == 'Login') {
        next()
      } else if (MEMBER_PAGE_FOR_BACKEND.includes(to.name)) {
        to.query.token = from.query.token
        next({ name: to.name, query: to.query })
      } else {
        next()
      }
    } else if (!(isUser || to.query.token) && auth === true) next({ name: 'Login', query: to.query })
    else if (isUser && auth === false) next({ name: 'MainIndex', query: to.query })
    else next({ name: to.name, query: to.query })
  } else {
    if (!to.query.token && from.query.token != undefined && from.query.token != '') {
      if (to.name == 'Login') {
        next()
      } else if (MEMBER_PAGE_FOR_BACKEND.includes(to.name)) {
        to.query.token = from.query.token
        next({ name: to.name, query: to.query })
      } else {
        next()
      }
    } else if (!(isUser || to.query.token) && auth === true) {
      next({ name: 'Login' })
    } else if (isUser && auth === false) next({ name: 'MainIndex' })
    else next()
  }
})

export default router
